import React, { useState } from 'react';


const Postcards = () => {
  const [imageSrc, setImageSrc] = useState('/A9790385-70C4-4939-9E8C-F88E58B46563.JPG');

  const swapImage = () => {
    setImageSrc(prevSrc =>
      prevSrc === '/A9790385-70C4-4939-9E8C-F88E58B46563.JPG' ? '/A327D884-1027-465E-BFA4-C0773020E5A1.JPG' : '/A9790385-70C4-4939-9E8C-F88E58B46563.JPG'
    );
  };

  return (
    <div className="postcards-container">
      <img id="toggleImage" src={imageSrc} alt="Toggle" onClick={swapImage} />
    </div>
  );
};

export default Postcards;