// src/authConfig.js

export const msalConfig = {
  auth: {
    clientId: "0a8ad5e3-ac07-42c3-8c03-1af9e0d84482", // Replace with your Azure AD client ID
    authority: "https://login.microsoftonline.com/811a573f-59e5-4226-86e4-99760b1ef283", // Replace with your tenant ID
    redirectUri: "https://rinitauz.eu", // Your app's redirect URI
  },
  cache: {
    cacheLocation: "sessionStorage", // or 'localStorage'
    storeAuthStateInCookie: false, // Set to true if your app is on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read", "Tasks.ReadWrite"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  todoEndpoint: "https://graph.microsoft.com/v1.0/me/todo/lists/AQMkADAwATM3ZmYAZS0wZGMxLTRhYQAwLTAwAi0wMAoALgAAAx6J2Ib4P-xGsUuFipPu2ZUBAPhTP-mo5i1HlaeY63JvuhkABmtBP5gAAAA=/tasks", // Replace with your specific To Do list ID
};
