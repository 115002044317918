import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../index.css'; // Ensure global styles are imported

const TaskList = () => {
  const [lists, setLists] = useState({ Taschkent: [], StuffToBring: [], Movies: [] });
  const [selectedList, setSelectedList] = useState('Taschkent');
  const [task, setTask] = useState('');

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://rinitauz.eu:800/tasks');
        const fetchedLists = { Taschkent: [], StuffToBring: [], Movies: [] };

        response.data.forEach(task => {
          if (task.list in fetchedLists) {
            fetchedLists[task.list].push(task); // Push the entire task object
          }
        });

        setLists(fetchedLists);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  const handleAddTask = async () => {
    if (task.trim() === '') return;
    const newTask = { text: task, done: false, list: selectedList }; // Match your backend's expected property name

    try {
      const response = await axios.post('https://rinitauz.eu:800/tasks', newTask);
      setLists((prevLists) => ({
        ...prevLists,
        [selectedList]: [...prevLists[selectedList], response.data], // Use the response data which includes the ID
      }));
      setTask('');
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const handleToggleTask = async (id) => {
    const taskToToggle = lists[selectedList].find(t => t.id === id); // Find the task by ID
    const updatedTask = { text: taskToToggle.text, done: !taskToToggle.done, list: selectedList }; // Include list
  
    try {
      await axios.put(`https://rinitauz.eu:800/tasks/${id}`, updatedTask);
      setLists((prevLists) => {
        const updatedTasks = prevLists[selectedList].map(t =>
          t.id === id ? { ...t, done: !t.done } : t
        );
        return { ...prevLists, [selectedList]: updatedTasks };
      });
    } catch (error) {
      console.error('Error toggling task:', error);
    }
  };
  
  

  const handleDeleteTask = async (id) => {
    try {
      await axios.delete(`https://rinitauz.eu:800/tasks/${id}`);
      setLists((prevLists) => {
        const updatedTasks = prevLists[selectedList].filter(t => t.id !== id); // Filter by ID
        return { ...prevLists, [selectedList]: updatedTasks };
      });
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleListChange = (event) => {
    setSelectedList(event.target.value);
  };

  const handleCreateNewList = () => {
    const newListName = prompt('Enter the name of the new list:');
    if (newListName && !lists[newListName]) {
      setLists((prevLists) => ({ ...prevLists, [newListName]: [] }));
      setSelectedList(newListName);
    }
  };

  return (
    <div className="task-list-container">
      <h2 className="task-list-title">Task Lists</h2>
      <div className="list-controls">
        <select value={selectedList} onChange={handleListChange} className="list-selector">
          {Object.keys(lists).map((listName) => (
            <option key={listName} value={listName}>
              {listName}
            </option>
          ))}
        </select>
        <button onClick={handleCreateNewList} className="create-list-button">
          Create New List
        </button>
      </div>
      <div className="task-input-container">
        <input
          type="text"
          value={task}
          onChange={(e) => setTask(e.target.value)}
          placeholder="Add a new task"
          className="task-input"
        />
        <button onClick={handleAddTask} className="add-task-button">
          Add Task
        </button>
      </div>
      <ul className="task-list">
        {lists[selectedList].map((task) => (
          <li key={task.id} className={`task-item ${task.done ? 'done' : ''}`}>
            <input
              type="checkbox"
              checked={task.done}
              onChange={() => handleToggleTask(task.id)} // Use task.id here
              className="task-checkbox"
            />
            <span className="task-text">{task.text}</span>
            <button onClick={() => handleDeleteTask(task.id)} className="delete-task-button">
              ❌
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskList;
