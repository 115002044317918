import React from 'react';

const Poems = () => {
  return (
    <div className="container">
      <p className="line-break">
        Hoch und fest, mein Dachboden,<br></br>
        Mal warm, mal kalt, es hat sich gesonnen.<br></br>
        Das Vertigo, wenn man runterschaut,<br></br>
        Ist längst gegangen, es mir vertraut.<br></br>

        Komm hoch, wenn du magst, schlaf hier in Ruhe<br></br>
        Ich träume mit, dass du hier bist dazu.<br></br>
        Wenn du lieber für dich schreiben magst, bei Tee, die ganze Nacht,<br></br>
        Träume ich von allem, was du gedacht.<br></br>

        Auf dem Weg nach Hause rufe ich dich an,<br></br>
        Bin in den Klassen des Offenseins.<br></br>
        Möchte, dass unsere kurze Ewigkeit<br></br>
        Ein Ort der Erfahrung und Freiheit sei.
      </p>
    </div>
  );
};

export default Poems;
