import React from 'react';

const Puzzles = () => {
  return (
    <div className="puzzles-container">
      <h1>For the trips of my love :*:</h1>
      {/* Embed the puzzle using an iframe */}
      <iframe
        height="600px"
        width="100%"
        allow="web-share; fullscreen"
        style={{
          border: 'none',
          width: '100% !important',
          position: 'static',
          display: 'block !important',
          margin: '0 !important',
        }}
        src="https://amuselabs.com/pmm/crossword?id=9e6d9f79&set=9288a77e0a43a936a7c1548feb9a86369dbcb05760dbbb16b28f0e3bf8f9923c&embed=1"
        aria-label="For the trips of my love :*"
        title="For the trips of my love :*"
      ></iframe>
    </div>
  );
};

export default Puzzles;
