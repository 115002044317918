import React from 'react';

const Letters = () => {
  return (
    <div className="container">
      <h1>Letters:</h1>
      <div className="letter">
        <p>15.08.2024 11:45 Dresden my mitte loft bed</p>
        <p>Dear Rinita,</p>

        <p>I’m just finishing writing in the golden bright notebook you gave me, jotting down some notes about my current work. There’s so much on my mind—I’ve been reflecting on the past, on our time together, and on the nature of time itself.</p>

        <p>As I think about the decisions ahead and the next steps in my life, I can’t help but remember my first day at work. I was so young then, and my life was so different. I also recall the day you moved in; I was younger than today, and my life changed once again. Reflecting on these memories, I slowly fill a page with thoughts I’ll share my boss tomorrow. It’s a relief to take everything out, and even more so when I glance at the note at the top of the page. It describes how a beautiful Angel, a Chihuahua, came as a passegener to say hi. It is beside a mysterious number 10, a number you couldn’t explain, but perhaps one that needs no explanation.</p>

        <p>When I began writing this, my intention was to apologize for the insecurities I’ve felt during this new chapter of our journey together. But now, after rethinking this letter, I realize what I truly want is to express my deep appreciation and gratitude for your support, both direct and indirect. Thank you for your understanding and patience with me.</p>

        <p>I’m just beginning to understand that, even though Im always up to step out of my comfort zone, nevertheless it becomes far more challenging when my decisions impact more than just myself. This reminds me of the moment I decided to leave my country. Just as I struggled then to give concrete reasons for my choice to my parents, I find myself facing a similar dilemma now. I can articulate the reasons, but they seem so small compared to the immense, intangible feeling of committing and taking the plunge to a water body. In that brief moment of eternity—when I’m in the air just before my body touches the water—everything feels certain, everything feels sure.</p>

        <p>I realize now that I had resisted taking the jump to the water now, recognizing my fear of the distance between us. But as I observe this countdown, I see that this plunge moment of eternity does have an expiration date, unlike to the feelings I have for you and the certainty I now have, without any insecurity, that you feel the same.</p>

        <p>Rinita, I love you. I love how you lighten the weight of this skydive, learning together how to fly. The highs and the free falls still scare me, but our lightness elevates me even higher. I’m learning how to love you, how to miss you. And with each passing moment, the fear fades away.</p>

        <p>Your Vici</p>
      </div>
    </div>
  );
};

export default Letters;