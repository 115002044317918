import React, { useState } from 'react';

const Sudoku = () => {
  // Initial pre-filled 9x9 grid (empty cells are '')
  const initialBoard = [
    [5, 3, '', '', 7, '', '', '', ''],
    [6, '', '', 1, 9, 5, '', '', ''],
    ['', 9, 8, '', '', '', '', 6, ''],
    [8, '', '', '', 6, '', '', '', 3],
    [4, '', '', 8, '', 3, '', '', 1],
    [7, '', '', '', 2, '', '', '', 6],
    ['', 6, '', '', '', '', 2, 8, ''],
    ['', '', '', 4, 1, 9, '', '', 5],
    ['', '', '', '', 8, '', '', 7, 9]
  ];

  const [board, setBoard] = useState(initialBoard);

  // Function to handle changes in input
  const handleInputChange = (row, col, value) => {
    if (/^[1-9]?$/.test(value)) { // Only allow numbers 1-9
      const newBoard = [...board];
      newBoard[row][col] = value;
      setBoard(newBoard);
    }
  };

  // Reset the Sudoku grid
  const resetBoard = () => {
    setBoard(initialBoard);
  };

  return (

    <div className="puzzles-container">
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Sudoku Game</h2>
      <div style={{ display: 'inline-block', border: '2px solid black', padding: '10px' }}>
        {board.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex' }}>
            {row.map((value, colIndex) => (
              <input
                key={`${rowIndex}-${colIndex}`}
                type="text"
                value={value}
                maxLength="1"
                onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                disabled={initialBoard[rowIndex][colIndex] !== ''} // Disable input for pre-filled values
                style={{
                  width: '40px',
                  height: '40px',
                  textAlign: 'center',
                  border: '1px solid black',
                  fontSize: '20px',
                  margin: '2px',
                  backgroundColor: (colIndex % 3 === 2 && rowIndex % 3 === 2) ? '#f0f0f0' : '#fff',
                  color: initialBoard[rowIndex][colIndex] !== '' ? 'black' : 'blue', // Blue for user input
                }}
              />
            ))}
          </div>
        ))}
      </div>
      <div>
        <button onClick={resetBoard} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
          Reset Board
        </button>
      </div>
    </div>
    </div>
  );
};

export default Sudoku;
