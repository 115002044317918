import React, { useEffect } from 'react';

const Countdown = () => {
  useEffect(() => {
    function updateCountdown() {
      const endDate = new Date('December 21, 2024 02:00:00 GMT+0500').getTime();
      const now = new Date().getTime();
      const distance = endDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById('countdown').innerHTML = `
        <div>
          <span>${days}</span>
          <label>Days</label>
        </div>
        <div>
          <span>${hours}</span>
          <label>Hours</label>
        </div>
        <div>
          <span>${minutes}</span>
          <label>Minutes</label>
        </div>
        <div>
          <span>${seconds}</span>
          <label>Seconds</label>
        </div>
      `;

      if (distance < 0) {
        clearInterval(interval);
        document.getElementById('countdown').innerHTML = "Te amo Liebi im so excited!";
      }
    }

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <h1>Countdown to <br />
        December 21st, <br />
        2:00 AM Tashkent Time!</h1>
      <div className="countdown" id="countdown"></div>
      <div className="footer">
        <p>So excited to feel your warmth again my love!</p>
      </div>
    </div>
  );
};

export default Countdown;