import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import GamesIcon from '@mui/icons-material/Games';
import ImageIcon from '@mui/icons-material/Image';
import MessageIcon from '@mui/icons-material/Message';
import PoemsIcon from '@mui/icons-material/Book';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Countdown from './components/Countdown';
import Puzzles from './components/Puzzles';
import Postcards from './components/Postcards';
import Letters from './components/Letters';
import Poems from './components/Poems';
import TaskList from './components/TaskList';
import SudokuComponent from './components/Sudoku';

const drawerWidth = 240;

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        <ListItem button component={Link} to="/" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Countdown" />
        </ListItem>
        <ListItem button component={Link} to="/puzzles" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><GamesIcon /></ListItemIcon>
          <ListItemText primary="Puzzles" />
        </ListItem>
        <ListItem button component={Link} to="/postcards" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><ImageIcon /></ListItemIcon>
          <ListItemText primary="Postcards" />
        </ListItem>
        <ListItem button component={Link} to="/letters" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><MessageIcon /></ListItemIcon>
          <ListItemText primary="Letters" />
        </ListItem>
        <ListItem button component={Link} to="/poems" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><PoemsIcon /></ListItemIcon>
          <ListItemText primary="Poems" />
        </ListItem>
        <ListItem button component={Link} to="/sudoku" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><GamesIcon /></ListItemIcon>
          <ListItemText primary="Sudoku" />
        </ListItem>
        <ListItem button component={Link} to="/tasklist" onClick={() => setMobileOpen(false)}>
          <ListItemIcon><ChecklistIcon /></ListItemIcon>
          <ListItemText primary="Task List" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        
        {/* AppBar for Mobile Menu */}
        {isMobile && (
          <AppBar position="fixed" sx={{ display: { sm: 'none' } }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                rinita I'm here if you need anything :*
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        {/* Drawer Component */}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="menu folders"
        >
          {/* Mobile Drawer */}
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>

          {/* Permanent Drawer for Desktop */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        {/* Main Content Area */}
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Routes>
            <Route path="/" element={<Countdown />} />
            <Route path="/puzzles" element={<Puzzles />} />
            <Route path="/postcards" element={<Postcards />} />
            <Route path="/letters" element={<Letters />} />
            <Route path="/poems" element={<Poems />} />
            <Route path="/sudoku" element={<SudokuComponent />} />
            <Route path="/tasklist" element={<TaskList />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
